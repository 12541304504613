import React from "react"
import { graphql } from "gatsby"
import { useWindowSize } from "../util"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { theme } from "../theme"

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[6]};
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.normal};
  font-weight: ${({ theme }) => theme.fontWeights[0]};
  line-height: 120%;
  margin-bottom: ${({ theme }) => theme.space[3]};
`

const Subtitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.normal};
  line-height: 120%;
  margin-bottom: ${({ theme }) => theme.space[4]};
`

const TextContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(91.66659% - 16px);
  max-width: calc(91.66659% - 16px);
  justify-content: flex-start;
  font-family: ${({ theme }) => theme.fonts.text};

  @media (max-width: 768px) {
    flex-basis: calc(100%);
    max-width: calc(100%);
    font-size: 13px;
  }
`

const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const PageTemplate = ({ data }) => {
  const [width] = useWindowSize()
  return (
    <Layout>
      <SEO
        title={data.wordpressPage.title}
        description={
          data.wordpressPage.acf.seo_description
            ? data.wordpressPage.acf.seo_description
            : "Nikhil Bhanwra, nikhilbhanwra.com, Judy Marsales, Hamilton, Ontario, Burlington, Real Estate"
        }
      />
      <section className="section">
        <div
          className="container"
          style={{ maxWidth: 1064, padding: width > 768 ? `3rem 0` : `0` }}
        >
          <div className="columns">
            <div className="column  is-two-thirds">
              <TextContainer>
                {data.wordpressPage.acf.image && (
                  <Img
                    src={data.wordpressPage.acf.image.source_url}
                    style={{ marginBottom: 40 }}
                  />
                )}
                <br />
                <div
                  className="columns is-mobile is-multiline"
                  style={{ maxWidth: 500, height: 0 }}
                >
                  <div className="column is-half-mobile"></div>
                  <div className="column is-half-mobile"></div>
                </div>
                <div
                  className="service-body"
                  dangerouslySetInnerHTML={{
                    __html: data.wordpressPage.content,
                  }}
                ></div>
              </TextContainer>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default PageTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      content
      acf {
        seo_description
        image {
          source_url
        }
      }
    }
  }
`
